import React, { useEffect, useState } from 'react';
import '../styles/weather.css';

const Weather = () => {
  const [forecast, setForecast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    const getUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lon: longitude });
          },
          err => {
            setError("Unable to retrieve your location");
            setLoading(false);
          }
        );
      } else {
        setError("Geolocation is not supported by your browser");
        setLoading(false);
      }
    };

    getUserLocation();
  }, []);

  useEffect(() => {
    const fetchForecast = async () => {
      if (!userLocation) return;

      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${userLocation.lat}&lon=${userLocation.lon}&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}&units=imperial`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch forecast data');
        }
        const data = await response.json();
        setForecast(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchForecast();
  }, [userLocation]);

  if (loading) {
    return <div className="weather-card loading">Loading forecast...</div>;
  }

  if (error) {
    return <div className="weather-card error">Error: {error}</div>;
  }

  const getWeatherIcon = (iconCode) => {
    return `http://openweathermap.org/img/wn/${iconCode}@2x.png`;
  };

  const getDayOfWeek = (date) => {
    return new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
  };

  const groupedForecast = forecast.list.reduce((acc, item) => {
    const date = item.dt_txt.split(' ')[0];
    if (!acc[date]) {
      acc[date] = item;
    }
    return acc;
  }, {});

  const fiveDayForecast = Object.values(groupedForecast).slice(0, 5);

  return (
    <div className="weather-card">
      <h2>5-Day Forecast for {forecast.city.name}</h2>
      <div className="forecast-container">
        {fiveDayForecast.map((day, index) => (
          <div key={index} className="forecast-day">
            <h3>{getDayOfWeek(day.dt_txt)}</h3>
            <img 
              src={getWeatherIcon(day.weather[0].icon)} 
              alt={day.weather[0].description}
              className="weather-icon"
            />
            <p className="forecast-temp">{Math.round(day.main.temp)}°F</p>
            <p className="forecast-description">{day.weather[0].description}</p>
            <div className="forecast-details">
              <div className="forecast-detail">
                <span className="label">Humidity:</span>
                <span className="value">{day.main.humidity}%</span>
              </div>
              <div className="forecast-detail">
                <span className="label">Wind:</span>
                <span className="value">{day.wind.speed} m/s</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Weather;