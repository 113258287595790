import React from 'react';
import SignUp from '../components/signUp';
import '../styles/auth.css';
import Logo from '../imgs/Logo_V2.svg';

const SignUpPage = () => {
  return (
    <div className="auth-container">
      <div className="form-container">
        <img src={Logo} alt="Logo" className="form-logo" />
        <SignUp />
      </div>
    </div>
  );
};

export default SignUpPage;
