import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import OrderList from './pages/orderListPage';
import ProductList from './pages/productListPage';
import FarmSettingsPage from './pages/farmSettingsPage';
import UserSettingsPage from './pages/userSettingsPage';
import LoginPage from './pages/loginPage';
import SignUpPage from './pages/signUpPage';
import Dashboard from './pages/dashboardPage';
import CustomerListPage from './pages/customerListPage';
import InvoicesPage from './pages/invoicesPage';
import PrivateRoute from './components/privateRoute';
import { FarmProvider } from './contexts/farmContext';
import { getAnalytics, logEvent } from 'firebase/analytics';

function App() {
    return (
        <Router>
            <FarmProvider>
                <AnalyticsTracker />
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route
                        path='/'
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/order-list"
                        element={
                            <PrivateRoute>
                                <OrderList />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/products-list"
                        element={
                            <PrivateRoute>
                                <ProductList />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/farm-settings/:id"
                        element={
                            <PrivateRoute>
                                <FarmSettingsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user-settings/:userId"
                        element={
                            <PrivateRoute>
                                <UserSettingsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/customer-list"
                        element={
                            <PrivateRoute>
                                <CustomerListPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/invoices"
                        element={
                            <PrivateRoute>
                                <InvoicesPage />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </FarmProvider>
        </Router>
    );
}

function AnalyticsTracker() {
    const location = useLocation();
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_path: location.pathname
        });
    }, [location, analytics]);

    return null;
}

export default App;
