import OrderList from '../components/orderList.js';
import Sidebar from '../components/sidebar.js';
import '../styles/orderList.css';

function HomePage() {

    return (
        <div className="home-main-container">
            <div className="home-columns-group">
                <Sidebar />
                <div className="content">
                    <OrderList/>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
