import React from 'react';
import PropTypes from 'prop-types';
import '../styles/productModal.css';

function ProductModal({ show, onClose, onSubmit, product, handleInputChange, fetchProducts }) {
    if (!show) {
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSubmit(e);
        fetchProducts(); // Refresh product list
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Create New Product</h2>
                    <button onClick={onClose} className="close-button">&times;</button>
                </div>
                <div className="modal-body">
                    <form className="product-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Product Name</label>
                            <input
                                type="text"
                                name="productName"
                                value={product.productName}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Product Description</label>
                            <textarea
                                name="productDescription"
                                value={product.productDescription}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Product Unit</label>
                            <input
                                type="text"
                                name="productUnit"
                                value={product.productUnit}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Price</label>
                            <input
                                type="number"
                                name="price"
                                value={product.price}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Pricing Tier</label>
                            <input
                                type="text"
                                name="pricingTier"
                                value={product.pricingTier}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Unit Weight</label>
                            <input
                                type="number"
                                name="unitWeight"
                                value={product.unitWeight}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
                            <button type="submit" className="submit-button">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

ProductModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
};

export default ProductModal;
