import React from 'react';
import { FaEdit, FaTrash, FaFileInvoice } from 'react-icons/fa';
import { format } from 'date-fns';
import '../styles/orderSpreadsheetView.css';

function SpreadsheetView({ orders, weatherData, handleUpdate, handleDeleteClick, getWeatherStyle, showWeather, status, handleGenerateInvoice }) {
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return format(date, 'MM-dd-yyyy | hh:mm a');
    };

    const handleChange = (orderId, newStatus) => {
        if (typeof status === 'function') {
            status(orderId, newStatus);
        } else {
            console.error('status is not a function');
        }
    };

    return (
        <div className="spreadsheet-view">
            <table className="spreadsheet-table">
                <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Location</th>
                        <th>Product</th>
                        <th>Delivery Date</th>
                        <th>Status</th>
                        {showWeather && <th>Weather</th>}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td>{order.clientName}</td>
                            <td>{order.harvestLocation}</td>
                            <td>{order.productName}</td>
                            <td>{formatDateTime(order.deliveryDate)}</td>
                            <td>
                                <select
                                    name="status"
                                    value={order.status}
                                    onChange={(e) => handleChange(order.id, e.target.value)}
                                    className="form-control"
                                >
                                    <option value="Pending">Pending</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Awaiting Harvest">Awaiting Harvest</option>
                                    <option value="Harvested">Harvested</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Ready for Pickup">Ready for Pickup</option>
                                    <option value="In Transit">In Transit</option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Failed">Failed</option>
                                    <option value="On Hold">On Hold</option>
                                </select>
                            </td>
                            {showWeather && (
                                <td className={getWeatherStyle(weatherData[order.id] || '')}>
                                    {weatherData[order.id] || 'Loading...'}
                                </td>
                            )}
                            <td>
                                <button className="icon-button" onClick={() => handleUpdate(order.id)}>
                                    <FaEdit />
                                </button>
                                <button className="icon-button" onClick={() => handleDeleteClick(order.id)}>
                                    <FaTrash />
                                </button>
                                <button className="icon-button" onClick={() => handleGenerateInvoice(order)}>
                                    <FaFileInvoice />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SpreadsheetView;