import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AutocompleteAddressInput from './autoCompleteAddressInput';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebaseConfig';
import { useFarmContext } from '../contexts/farmContext';
import Select from 'react-select';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import PropTypes from 'prop-types';
import '../styles/orderModal.css';
import { format, parse } from 'date-fns';

function UpdateOrderModal({ show, onClose, orderId, fetchOrders }) {
    const [user] = useAuthState(auth);
    const { farmId } = useFarmContext();
    const [order, setOrder] = useState({
        clientName: '',
        userId: '',
        orderType: 'Delivery',
        deliveryAddress: '',
        harvestLocation: '',
        pickupLocation: '',
        deliveryDate: new Date(),
        deliveryTime: format(new Date(), 'HH:mm'),
        pickupDate: new Date(),
        pickupTime: format(new Date(), 'HH:mm'),
        harvestDate: new Date(),
        harvestTime: format(new Date(), 'HH:mm'),
        productName: '',
        amount: '',
        productUnit: '',
        cost: '',
        shippingCost: '',
        total: 0,
        additionalComments: '',
        status: 'Pending'
    });

    const [products, setProducts] = useState([]);
    const [farm, setFarm] = useState(null);
    const [customers, setCustomers] = useState([]);

    const fetchOrderDetails = useCallback(async () => {
        if (!orderId) return;
        
        try {
            const orderRef = doc(db, 'orders', orderId);
            const orderSnap = await getDoc(orderRef);
            if (orderSnap.exists()) {
                const orderData = orderSnap.data();
                setOrder({
                    ...orderData,
                    deliveryDate: orderData.deliveryDate ? parse(orderData.deliveryDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()) : new Date(),
                    pickupDate: orderData.pickupDate ? parse(orderData.pickupDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()) : new Date(),
                    harvestDate: orderData.harvestDate ? parse(orderData.harvestDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()) : new Date(),
                    deliveryTime: orderData.deliveryTime || format(new Date(), 'HH:mm'),
                    pickupTime: orderData.pickupTime || format(new Date(), 'HH:mm'),
                    harvestTime: orderData.harvestTime || format(new Date(), 'HH:mm'),
                });
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    }, [orderId]);

    const fetchFarm = useCallback(async () => {
        if (!farmId) return;

        try {
            const farmRef = doc(db, 'farms', farmId);
            const farmSnap = await getDoc(farmRef);
            if (farmSnap.exists()) {
                setFarm(farmSnap.data());
            }
        } catch (error) {
            console.error('Error fetching farm:', error);
        }
    }, [farmId]);

    const fetchProducts = useCallback(async () => {
        if (!farmId) return;

        try {
            const productsQuery = query(
                collection(db, 'products'),
                where('farmId', '==', farmId)
            );
            const productsSnapshot = await getDocs(productsQuery);
            const productsData = productsSnapshot.docs.map(doc => doc.data());
            setProducts(productsData);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, [farmId]);

    const fetchCustomers = useCallback(async () => {
        if (!farmId) return;

        try {
            const customersQuery = query(
                collection(db, 'customers'),
                where('farmId', '==', farmId)
            );
            const customersSnapshot = await getDocs(customersQuery);
            const customersData = customersSnapshot.docs.map(doc => doc.data());
            setCustomers(customersData);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    }, [farmId]);

    useEffect(() => {
        if (orderId) {
            fetchOrderDetails();
        }
    }, [orderId, fetchOrderDetails]);

    useEffect(() => {
        if (farmId) {
            fetchFarm();
            fetchProducts();
            fetchCustomers();
        }
    }, [farmId, fetchFarm, fetchProducts, fetchCustomers]);

    useEffect(() => {
        if (user) {
            setOrder(prevOrder => ({ ...prevOrder, userId: user.uid }));
        }
    }, [user]);

    useEffect(() => {
        const amount = parseFloat(order.amount) || 0;
        const cost = parseFloat(order.cost) || 0;
        const shippingCost = parseFloat(order.shippingCost) || 0;
        const total = amount * cost + (order.orderType === 'Delivery' ? shippingCost : 0);
        setOrder(prevOrder => ({ ...prevOrder, total }));
    }, [order.amount, order.cost, order.shippingCost, order.orderType]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setOrder(prevOrder => ({ ...prevOrder, [name]: inputValue }));
    };

    const handleDateChange = (date, name) => {
        setOrder(prevOrder => ({ ...prevOrder, [name]: date }));
    };

    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setOrder(prevOrder => ({ ...prevOrder, [name]: value }));
    };

    const handleProductNameChange = (e) => {
        const productName = e.target.value;
        const selectedProduct = products.find(product => product.productName === productName);
        const cost = selectedProduct ? selectedProduct.price : '';
        const productUnit = selectedProduct ? selectedProduct.productUnit : '';
        setOrder(prevOrder => ({ ...prevOrder, productName, cost, productUnit }));
    };

    const handleAddressChange = (address) => {
        setOrder(prevOrder => ({ ...prevOrder, deliveryAddress: address }));
    };

    const handleCustomerChange = (selectedOption) => {
        setOrder(prevOrder => ({ ...prevOrder, clientName: selectedOption ? `${selectedOption.firstName} ${selectedOption.lastName}` : '' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const combinedOrder = {
                ...order,
                deliveryDate: order.deliveryDate ? format(parse(`${format(order.deliveryDate, 'yyyy-MM-dd')} ${order.deliveryTime}`, 'yyyy-MM-dd HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss") : null,
                pickupDate: order.pickupDate ? format(parse(`${format(order.pickupDate, 'yyyy-MM-dd')} ${order.pickupTime}`, 'yyyy-MM-dd HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss") : null,
                harvestDate: order.harvestDate ? format(parse(`${format(order.harvestDate, 'yyyy-MM-dd')} ${order.harvestTime}`, 'yyyy-MM-dd HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss") : null,
            };

            const orderRef = doc(db, 'orders', orderId);
            await updateDoc(orderRef, { ...combinedOrder, farmId });
            fetchOrders(); // Refresh order list
            onClose();
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Update Order</h2>
                    <button onClick={onClose} className="close-button">&times;</button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit} className="order-form">
                        <div className="form-group">
                            <label>Customer</label>
                            <Select
                                name="clientName"
                                value={customers.find(customer => `${customer.firstName} ${customer.lastName}` === order.clientName)}
                                onChange={handleCustomerChange}
                                options={customers}
                                getOptionLabel={(customer) => `${customer.firstName} ${customer.lastName}`}
                                getOptionValue={(customer) => `${customer.firstName} ${customer.lastName}`}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>Order Type</label>
                            <select name="orderType" value={order.orderType} onChange={handleInputChange} className="form-control">
                                <option value="Delivery">Delivery</option>
                                <option value="Pickup">Pickup</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Status</label>
                            <select name="status" value={order.status} onChange={handleInputChange} className="form-control">
                                <option value="Pending">Pending</option>
                                <option value="Confirmed">Confirmed</option>
                                <option value="Awaiting Harvest">Awaiting Harvest</option>
                                <option value="Harvested">Harvested</option>
                                <option value="Processing">Processing</option>
                                <option value="Ready for Pickup">Ready for Pickup</option>
                                <option value="In Transit">In Transit</option>
                                <option value="Delivered">Delivered</option>
                                <option value="Completed">Completed</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Failed">Failed</option>
                                <option value="On Hold">On Hold</option>
                            </select>
                        </div>
                        {order.orderType === 'Delivery' && (
                            <>
                                <div className="form-group">
                                    <label>Delivery Address</label>
                                    <AutocompleteAddressInput
                                        value={order.deliveryAddress}
                                        onChange={handleAddressChange}
                                        className="autocomplete-address-input"
                                    />
                                </div>
                                <div className="form-group-compact">
                                    <div className="form-group">
                                        <label>Delivery Date</label>
                                        <DatePicker
                                            selected={order.deliveryDate}
                                            onChange={(date) => handleDateChange(date, 'deliveryDate')}
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Delivery Time</label>
                                        <input
                                            type="time"
                                            name="deliveryTime"
                                            value={order.deliveryTime}
                                            onChange={handleTimeChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="form-group-compact">
                            <div className="form-group">
                                <label>Harvest Date</label>
                                <DatePicker
                                    selected={order.harvestDate}
                                    onChange={(date) => handleDateChange(date, 'harvestDate')}
                                    dateFormat="yyyy/MM/dd"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Harvest Time</label>
                                <input
                                    type="time"
                                    name="harvestTime"
                                    value={order.harvestTime}
                                    onChange={handleTimeChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Harvest Location</label>
                            <select name="harvestLocation" value={order.harvestLocation} onChange={handleInputChange} className="form-control">
                                <option value="">Select Harvest Location</option>
                                {farm && farm.locations.map((location, index) => (
                                    <option key={index} value={location.address}>{location.name}: {location.address}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Product Name</label>
                            <select
                                name="productName"
                                value={order.productName}
                                onChange={handleProductNameChange}
                                className="form-control"
                            >
                                <option value="">Select Product</option>
                                {products.map((product, index) => (
                                    <option key={index} value={product.productName}>
                                        {product.productName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group-compact">
                            <div className="form-group">
                                <label>Amount</label>
                                <input
                                    type="text"
                                    name="amount"
                                    value={order.amount}
                                    onChange={handleInputChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Unit</label>
                                <input
                                    type="text"
                                    name="productUnit"
                                    value={order.productUnit}
                                    readOnly
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Cost</label>
                                <input
                                    type="text"
                                    name="cost"
                                    value={order.cost}
                                    readOnly
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {order.orderType === 'Delivery' && (
                            <div className="form-group-compact">
                                <div className="form-group">
                                    <label>Shipping Cost</label>
                                    <input
                                        type="text"
                                        name="shippingCost"
                                        value={order.shippingCost}
                                        onChange={handleInputChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Total</label>
                                    <input
                                        type="text"
                                        name="total"
                                        value={order.total.toFixed(2)}
                                        readOnly
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group">
                            <label>Additional Comments</label>
                            <textarea
                                name="additionalComments"
                                value={order.additionalComments}
                                onChange={handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
                            <button type="submit" className="submit-button">Update Order</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

UpdateOrderModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    fetchOrders: PropTypes.func.isRequired,
};

export default UpdateOrderModal;
