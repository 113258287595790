import React from 'react';
import PropTypes from 'prop-types';
import '../styles/filterBar.css';

function ProductFilterBar({ filters, handleFilterChange, resetFilters, products }) {
    return (
        <div className="filter-container">
            <input
                type="text"
                name="searchName"
                value={filters.searchName}
                onChange={handleFilterChange}
                className="filter-input"
                placeholder="Search by Product Name"
            />
            <select
                name="product"
                value={filters.product}
                onChange={handleFilterChange}
                className="filter-select"
            >
                <option value="All">All Products</option>
                {products.map((product, index) => (
                    <option key={index} value={product}>
                        {product}
                    </option>
                ))}
            </select>
            <button onClick={resetFilters} className="reset-filters">
                Reset Filters
            </button>
        </div>
    );
}

ProductFilterBar.propTypes = {
    filters: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
};

export default ProductFilterBar;