import React, { useEffect, useRef, useState } from 'react';

function AutocompleteAddressInput({ value, onChange }) {
    const inputRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [isAutocompleteSelection, setIsAutocompleteSelection] = useState(false);

    useEffect(() => {
        if (!window.google) {
            console.error('Google Maps JavaScript API not loaded');
            return;
        }

        autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
            types: ['address'],
        });

        const listener = autocompleteRef.current.addListener('place_changed', () => {
            const place = autocompleteRef.current.getPlace();
            const selectedAddress = place.formatted_address || inputRef.current.value;
            setIsAutocompleteSelection(true);
            onChange(selectedAddress);
        });

        return () => {
            if (listener) window.google.maps.event.removeListener(listener);
            if (autocompleteRef.current) {
                window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
            }
        };
    }, [onChange]);

    const handleInputChange = (e) => {
        setIsAutocompleteSelection(false);
        onChange(e.target.value);
    };

    const handleBlur = () => {
        if (!isAutocompleteSelection) {
            onChange(inputRef.current.value);
        }
    };

    return (
        <input
            type="text"
            ref={inputRef}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="form-control"
            placeholder="Enter Address"
        />
    );
}

export default AutocompleteAddressInput;
