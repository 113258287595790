import React from 'react';
import Sidebar from '../components/sidebar';
import UserSettings from '../components/userSettings';
import '../styles/userSettings.css'; 

function UserSettingsPage() {
    return (
        <div className="user-settings-main-container">
            <div className="userSettings-column-container">
                <Sidebar />
                <div className="userSettings-content">
                    <UserSettings />
                </div>
            </div>
        </div>
    );
}

export default UserSettingsPage;
