import React, { useEffect } from 'react';
import '../styles/filterBar.css';

function FilterBar({ filters, handleFilterChange, resetFilters, locations, products, showDateFilter }) {

    // Ensure locations and products are available as options
    useEffect(() => {
        if (locations.length === 0) {
            console.warn('No locations available');
        }
        if (products.length === 0) {
            console.warn('No products available');
        }
    }, [locations, products]);

    return (
        <div className="filter-container">
            <input
                type="text"
                name="searchName"
                value={filters.searchName}
                onChange={handleFilterChange}
                placeholder="Search Name"
                className="filter-input"
            />
            <select name="location" value={filters.location} onChange={handleFilterChange} className="filter-select">
                <option value="All">Location: All</option>
                {locations && locations.map((location, index) => (
                    <option key={index} value={location.address}>{location.name}</option>
                ))}
            </select>
            <select name="product" value={filters.product} onChange={handleFilterChange} className="filter-select">
                <option value="All">Product: All</option>
                {products && products.map((product, index) => (
                    <option key={index} value={product}>{product}</option>
                ))}
            </select>
            {showDateFilter && (
                <select name="date" value={filters.date} onChange={handleFilterChange} className="filter-select">
                    <option value="All">Date: All</option>
                    <option value="Today">Today</option>
                    <option value="ThisWeek">This Week</option>
                    <option value="ThisMonth">This Month</option>
                    <option value="LastMonth">Last Month</option>
                </select>
            )}
            <button onClick={resetFilters} className="reset-filters">Reset</button>
        </div>
    );
}

export default FilterBar;
