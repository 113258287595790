import React, { useState, useEffect, useCallback } from 'react';
import { useFarmContext } from '../contexts/farmContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../firebaseConfig'; 
import '../styles/farmSettings.css';

const FarmSettings = () => {
    const { farmId } = useFarmContext();
    const [farmData, setFarmData] = useState({
        name: '',
        subscription: '',
        locations: [],
        users: 0,
    });
    const [newLocation, setNewLocation] = useState({ name: '', address: '' });
    const [editingIndex, setEditingIndex] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const fetchFarmData = useCallback(async () => {
        try {
            const farmDocRef = doc(db, 'farms', farmId);
            const farmDoc = await getDoc(farmDocRef);
            
            if (farmDoc.exists()) {
                const data = farmDoc.data();
                setFarmData({
                    name: data.name || '',
                    subscription: data.subscription || '',
                    locations: data.locations || [],
                    users: data.users || 0,
                });
            } else {
                console.log('No farm found with the given farmId');
                toast.error("Failed to load farm data");
            }
        } catch (error) {
            console.error('Error fetching farm data:', error);
            toast.error("Error loading farm data");
        }
    }, [farmId]);

    useEffect(() => {
        fetchFarmData();
    }, [fetchFarmData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFarmData(prev => ({ ...prev, [name]: value }));
    };

    const handleAddLocation = (e) => {
        e.preventDefault();
        setFarmData(prev => ({
            ...prev,
            locations: [...prev.locations, newLocation]
        }));
        setNewLocation({ name: '', address: '' });
    };

    const handleEditLocation = (index) => {
        setEditingIndex(index);
        setNewLocation(farmData.locations[index]);
    };

    const handleUpdateLocation = (e) => {
        e.preventDefault();
        const updatedLocations = [...farmData.locations];
        updatedLocations[editingIndex] = newLocation;
        setFarmData(prev => ({
            ...prev,
            locations: updatedLocations
        }));
        setNewLocation({ name: '', address: '' });
        setEditingIndex(null);
    };

    const handleSaveSettings = async () => {
        setIsSaving(true);
        try {
            const farmDocRef = doc(db, 'farms', farmId);
            await updateDoc(farmDocRef, farmData);
            toast.success("Settings saved successfully", {
                position: 'bottom-right',
                style: { backgroundColor: "#023020", color: "#FFFFFF" },
                progressStyle: { background: "#FFFFFF" },
                icon: false,
            });
        } catch (error) {
            console.error('Error saving farm settings:', error);
            toast.error("Failed to save settings");
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="farm-settings-container">
            <ToastContainer />
            <div className="header">
                <h1>Farm Settings</h1>
                <button className="save-button" onClick={handleSaveSettings} disabled={isSaving}>
                    {isSaving ? 'SAVING...' : 'SAVE SETTINGS'}
                </button>
            </div>

            <div className="section general-info">
                <h2>General Information</h2>
                <div className="input-group">
                    <label htmlFor="farmName">Farm Name</label>
                    <input
                        id="farmName"
                        name="name"
                        type="text"
                        value={farmData.name}
                        onChange={handleInputChange}
                        placeholder="Farm Name"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="subscription">Subscription</label>
                    <input
                        id="subscription"
                        name="subscription"
                        type="text"
                        value={farmData.subscription}
                        onChange={handleInputChange}
                        placeholder="Subscription"
                        disabled
                    />
                </div>
                <div className="input-group">
                    <label>Number of Users</label>
                    <p>{farmData.users}</p>
                </div>
            </div>

            <div className="section locations">
                <h2>Locations</h2>
                {farmData.locations.map((location, index) => (
                    <div key={index} className="location-item">
                        <p><strong>{location.name}: </strong>{location.address}</p>
                        <button className="edit-button" onClick={() => handleEditLocation(index)}>Edit</button>
                    </div>
                ))}
                <form onSubmit={editingIndex !== null ? handleUpdateLocation : handleAddLocation} className="add-location-form">
                    <div className="input-group">
                        <label htmlFor="locationName">Location Name</label>
                        <input
                            id="locationName"
                            type="text"
                            value={newLocation.name}
                            onChange={(e) => setNewLocation({ ...newLocation, name: e.target.value })}
                            placeholder="Location Name"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="locationAddress">Address</label>
                        <input
                            id="locationAddress"
                            type="text"
                            value={newLocation.address}
                            onChange={(e) => setNewLocation({ ...newLocation, address: e.target.value })}
                            placeholder="Address"
                            required
                        />
                    </div>
                    <button type="submit" className="add-button">
                        {editingIndex !== null ? 'UPDATE LOCATION' : 'ADD LOCATION'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default FarmSettings;