import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import '../styles/auth.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const analytics = getAnalytics();

    try {
      await signInWithEmailAndPassword(auth, email, password);

      logEvent(analytics, 'login', {
        method: 'Email',
        email: email
      });

      navigate('/');
      
      logEvent(analytics, 'navigation', {
        from: 'Login Page',
        to: 'Home Page'
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLearnMoreClick = () => {
    window.open('https://www.terracetech.co');
  };

  return (
    <div className="form">
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        {error && <p>{error}</p>}
        <p className="message">
          Learn more about <button type="button" className="link-button" onClick={handleLearnMoreClick}>Terrace</button>
        </p>
      </form>
    </div>
  );
};

export default Login;
