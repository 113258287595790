import React, { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../styles/orderCard.css';

function OrderCard({ order, weatherData, handleUpdate, handleDeleteClick, getWeatherStyle, showWeather, status }) {
    const [currentOrder, setCurrentOrder] = useState(order);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setCurrentOrder(prevOrder => ({ ...prevOrder, [name]: inputValue }));
        if (name === 'status') {
            status(order.id, value); // Call the status function passed as a prop
        }
    };

    return (
        <div className="order-card">
            <div className="order-card-header">
                <h3 onClick={() => handleUpdate(order.id)}>Order: {order.clientName}</h3>
                <div className="order-actions">
                    <button className="icon-button" onClick={(e) => { e.stopPropagation(); handleUpdate(order.id); }}>
                        <FaEdit />
                    </button>
                    <button className="icon-button" onClick={(e) => { e.stopPropagation(); handleDeleteClick(order.id); }}>
                        <FaTrash />
                    </button>
                </div>
            </div>
            <div className="order-card-body" onClick={() => handleUpdate(order.id)}>
                <p>Location: {order.harvestLocation}</p>
                <p>Product: {order.productName}</p>
                <p>Delivery Date: {order.formattedDeliveryDate}</p>
                <p>Total: {order.total}</p>
            </div>
            <div className="status-container" onClick={(e) => e.stopPropagation()}>
                <label>Status:</label>
                <select name="status" value={currentOrder.status} onChange={handleChange} className="form-control">
                    <option value="Pending">Pending</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="Awaiting Harvest">Awaiting Harvest</option>
                    <option value="Harvested">Harvested</option>
                    <option value="Processing">Processing</option>
                    <option value="Ready for Pickup">Ready for Pickup</option>
                    <option value="In Transit">In Transit</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Failed">Failed</option>
                    <option value="On Hold">On Hold</option>
                </select>
            </div>
        </div>
    );
}

export default OrderCard;
