import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../styles/customerSpreadsheetView.css';

function CustomerSpreadsheetView({ customers, handleUpdate, handleDeleteClick }) {
    return (
        <div className="spreadsheet-view">
            <table className="spreadsheet-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Customer Type</th>
                        <th>Address</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map(customer => (
                        <tr key={customer.id}>
                            <td>{`${customer.firstName} ${customer.lastName}`}</td>
                            <td>{customer.email}</td>
                            <td>{customer.phone}</td>
                            <td>{customer.type}</td>
                            <td>{customer.address}</td>
                            <td>
                                <div className="customer-actions">
                                    <button className="icon-button" onClick={() => handleUpdate(customer)}>
                                        <FaEdit />
                                    </button>
                                    <button className="icon-button" onClick={() => handleDeleteClick(customer.id)}>
                                        <FaTrash />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CustomerSpreadsheetView;
