import React from 'react';
import './modal.css';

const Modal = ({ show, onClose, onConfirm, message }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>Confirmation</h2>
                <p>{message}</p>
                <div className="modal-buttons">
                    <button className="button" onClick={onConfirm}>Yes</button>
                    <button className="button" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;