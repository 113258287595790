import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import AutocompleteAddressInput from './autoCompleteAddressInput';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebaseConfig';
import { useFarmContext } from '../contexts/farmContext';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import '../styles/orderModal.css';
import { collection, query, where, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { format, parse } from 'date-fns';

function OrderModal({ show, onClose, fetchOrders }) {
    const [user] = useAuthState(auth);
    const { farmId } = useFarmContext();
    
    // Initial order state to reset easily
    const initialOrderState = {
        clientName: '',
        userId: '',
        orderType: 'Delivery',
        deliveryAddress: '',
        harvestLocation: '',
        pickupLocation: '',
        deliveryDate: new Date(),
        deliveryTime: format(new Date(), 'HH:mm'),
        pickupDate: new Date(),
        pickupTime: format(new Date(), 'HH:mm'),
        harvestDate: new Date(),
        harvestTime: format(new Date(), 'HH:mm'),
        productName: '',
        amount: '',
        productUnit: '',
        cost: '',
        shippingCost: '',
        total: 0,
        additionalComments: '',
        status: 'Pending'
    };

    const [order, setOrder] = useState(initialOrderState);
    const [products, setProducts] = useState([]);
    const [farm, setFarm] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [newCustomer, setNewCustomer] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: 'Homeowner', 
        businessName: '',
        address: ''
    });
    const [activeTab, setActiveTab] = useState('existingCustomer');
    const [customerAdded, setCustomerAdded] = useState(false);
    const [addressKey, setAddressKey] = useState(0);



    const navigate = useNavigate();

    const fetchFarm = useCallback(async () => {
        try {
            const farmDoc = await getDoc(doc(db, 'farms', farmId));
            if (farmDoc.exists()) {
                setFarm(farmDoc.data());
            }
        } catch (error) {
            console.error('Error fetching farm:', error);
        }
    }, [farmId]);

    const fetchProducts = useCallback(async () => {
        try {
            const productsRef = collection(db, 'products');
            const q = query(productsRef, where('farmId', '==', farmId));
            const querySnapshot = await getDocs(q);
            const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProducts(productsData);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, [farmId]);

    const fetchCustomers = useCallback(async () => {
        try {
            const customersRef = collection(db, 'customers');
            const q = query(customersRef, where('farmId', '==', farmId));
            const querySnapshot = await getDocs(q);
            const customersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCustomers(customersData);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    }, [farmId]);

    useEffect(() => {
        if (farmId) {
            fetchFarm();
            fetchProducts();
            fetchCustomers();
        }
    }, [farmId, fetchFarm, fetchProducts, fetchCustomers]);

    useEffect(() => {
        if (user) {
            setOrder(prevOrder => ({ ...prevOrder, userId: user.uid }));
        }
    }, [user]);

    useEffect(() => {
        const amount = parseFloat(order.amount) || 0;
        const cost = parseFloat(order.cost) || 0;
        const shippingCost = parseFloat(order.shippingCost) || 0;
        const total = amount * cost + (order.orderType === 'Delivery' ? shippingCost : 0);
        setOrder(prevOrder => ({ ...prevOrder, total }));
    }, [order.amount, order.cost, order.shippingCost, order.orderType]);
    
    useEffect(() => {
        if (show) {
            setAddressKey(prevKey => prevKey + 1);
        }
    }, [show]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setOrder(prevOrder => ({ ...prevOrder, [name]: inputValue }));
    };    

    const handleDateChange = (date, name) => {
        setOrder(prevOrder => ({ ...prevOrder, [name]: date }));
    };

    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setOrder(prevOrder => ({ ...prevOrder, [name]: value }));
    };

    const handleHarvestLocationChange = (e) => {
        const harvestLocation = e.target.value;
        setOrder(prevOrder => ({ ...prevOrder, harvestLocation }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const combinedOrder = {
                ...order,
                deliveryDate: order.deliveryDate ? format(parse(`${format(order.deliveryDate, 'yyyy-MM-dd')} ${order.deliveryTime}`, 'yyyy-MM-dd HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss") : null,
                pickupDate: order.pickupDate ? format(parse(`${format(order.pickupDate, 'yyyy-MM-dd')} ${order.pickupTime}`, 'yyyy-MM-dd HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss") : null,
                harvestDate: order.harvestDate ? format(parse(`${format(order.harvestDate, 'yyyy-MM-dd')} ${order.harvestTime}`, 'yyyy-MM-dd HH:mm', new Date()), "yyyy-MM-dd'T'HH:mm:ss") : null,
            };
            await addDoc(collection(db, 'orders'), { ...combinedOrder, farmId });
            setOrder(initialOrderState); // Reset order state after submission
            fetchOrders();
            navigate('/order-list');
            onClose();
        } catch (error) {
            console.error('Error adding order:', error);
        }
    };

    const handleProductNameChange = (e) => {
        const productName = e.target.value;
        const selectedProduct = products.find(product => product.productName === productName);
        const cost = selectedProduct ? selectedProduct.price : '';
        const productUnit = selectedProduct ? selectedProduct.productUnit : '';
        setOrder(prevOrder => ({ ...prevOrder, productName, cost, productUnit }));
    };

    const handleAddressChange = (address) => {
        setOrder(prevOrder => ({ ...prevOrder, deliveryAddress: address }));
    };

    const handleNewCustomerChange = (e) => {
        const { name, value } = e.target;
        setNewCustomer(prevCustomer => ({ ...prevCustomer, [name]: value }));
    };

    const handleNewCustomerAddressChange = (address) => {
        setNewCustomer(prevCustomer => ({ ...prevCustomer, address }));
    };

    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        setNewCustomer(prevCustomer => ({ ...prevCustomer, [name]: value }));
    };

    const handleAddNewCustomer = async () => {
        try {
            const docRef = await addDoc(collection(db, 'customers'), {
                ...newCustomer,
                farmId
            });
            const newCustomerWithId = { id: docRef.id, ...newCustomer };
            setCustomers(prevCustomers => [...prevCustomers, newCustomerWithId]);
            setOrder(prevOrder => ({ ...prevOrder, clientName: `${newCustomerWithId.firstName} ${newCustomerWithId.lastName}` }));
            setCustomerAdded(true);
            setTimeout(() => setCustomerAdded(false), 3000);
        } catch (error) {
            console.error('Error adding new customer:', error);
        }
    };

    const handleCustomerChange = (selectedOption) => {
        setOrder(prevOrder => ({ ...prevOrder, clientName: selectedOption ? `${selectedOption.firstName} ${selectedOption.lastName}` : '' }));
    };

    const handleModalClose = () => {
        setOrder(initialOrderState); // Reset form when modal is closed
        onClose();
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Create New Order</h2>
                    <button onClick={handleModalClose} className="close-button">&times;</button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit} className="create-order-form">
                        <div className="tabs">
                            <div className={`tab ${activeTab === 'existingCustomer' ? 'active' : ''}`} onClick={() => setActiveTab('existingCustomer')}>Existing Customer</div>
                            <div className={`tab ${activeTab === 'newCustomer' ? 'active' : ''}`} onClick={() => setActiveTab('newCustomer')}>New Customer</div>
                        </div>
                        {activeTab === 'existingCustomer' && (
                            <div className="form-group-compact">
                                <div className="form-group">
                                    <label>Customer</label>
                                    <Select
                                        name="clientName"
                                        value={customers.find(customer => `${customer.firstName} ${customer.lastName}` === order.clientName)}
                                        onChange={handleCustomerChange}
                                        options={customers}
                                        getOptionLabel={(customer) => `${customer.firstName} ${customer.lastName}`}
                                        getOptionValue={(customer) => `${customer.firstName} ${customer.lastName}`}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Order Type</label>
                                    <select name="orderType" value={order.orderType} onChange={handleChange} className="form-control">
                                        <option value="Delivery">Delivery</option>
                                        <option value="Pickup">Pickup</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        {activeTab === 'newCustomer' && (
                            <div className="new-customer-form">
                                <div className="form-group-compact">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" name="firstName" value={newCustomer.firstName} onChange={handleNewCustomerChange} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" name="lastName" value={newCustomer.lastName} onChange={handleNewCustomerChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group-compact">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" value={newCustomer.email} onChange={handleNewCustomerChange} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <InputMask
                                            mask="(999) 999-9999"
                                            value={newCustomer.phone}
                                            onChange={handlePhoneChange}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" name="phone" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="form-group-compact">
                                    <div className="form-group">
                                        <label>Customer Type</label>
                                        <select name="type" value={newCustomer.type} onChange={handleNewCustomerChange} className="form-control">
                                            <option value="Homeowner">Homeowner</option>
                                            <option value="Business">Business</option>
                                        </select>
                                    </div>
                                    {newCustomer.type === 'Business' && (
                                        <div className="form-group">
                                            <label>Business Name</label>
                                            <input type="text" name="businessName" value={newCustomer.businessName} onChange={handleNewCustomerChange} className="form-control" />
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Address</label>
                                    <AutocompleteAddressInput
                                        value={newCustomer.address}
                                        onChange={handleNewCustomerAddressChange}
                                        className="autocomplete-address-input"
                                    />
                                </div>
                                <button type="button" onClick={handleAddNewCustomer} className="add-customer-button">Add Customer</button>
                                {customerAdded && <div className="success-message">Customer added successfully!</div>}
                            </div>
                        )}
                        {order.orderType === 'Delivery' && (
                            <>
                            <div className="form-group-compact">
                                    <div className="form-group">
                                            <label>Product Name</label>
                                    <select
                                        name="productName"
                                        value={order.productName}
                                        onChange={handleProductNameChange}
                                        className="form-control"
                                    >
                                        <option value="">Select Product</option>
                                        {products.map((product, index) => (
                                            <option key={index} value={product.productName}>
                                                {product.productName}
                                            </option>
                                        ))}
                            </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Amount</label>
                                    <input
                                        type="text"
                                        name="amount"
                                        value={order.amount}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                    </div>
                                </div>

                                <div className="form-group">
                                <label>Delivery Address</label>
                                <AutocompleteAddressInput
                                    key={addressKey}
                                    value={order.deliveryAddress}
                                    onChange={handleAddressChange}
                                    className="autocomplete-address-input"
                                />
                                </div>
                                <div className="form-group-compact">
                                    <div className="form-group">
                                        <label>Delivery Date</label>
                                        <DatePicker
                                            selected={order.deliveryDate}
                                            onChange={(date) => handleDateChange(date, 'deliveryDate')}
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Delivery Time</label>
                                        <input
                                            type="time"
                                            name="deliveryTime"
                                            value={order.deliveryTime}
                                            onChange={handleTimeChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            
                            </>
                        )}
                        {order.orderType === 'Pickup' && (
                            <>
                                <div className="form-group-compact">
                                    <div className="form-group">
                                        <label>Pickup Date</label>
                                        <DatePicker
                                            selected={order.pickupDate}
                                            onChange={(date) => handleDateChange(date, 'pickupDate')}
                                            dateFormat="yyyy/MM/dd"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Pickup Time</label>
                                        <input
                                            type="time"
                                            name="pickupTime"
                                            value={order.pickupTime}
                                            onChange={handleTimeChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="form-group-compact">
                            <div className="form-group">
                                <label>Harvest Date</label>
                                <DatePicker
                                    selected={order.harvestDate}
                                    onChange={(date) => handleDateChange(date, 'harvestDate')}
                                    dateFormat="yyyy/MM/dd"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                            <label>Harvest Time</label>
                                <input
                                    type="time"
                                    name="harvestTime"
                                    value={order.harvestTime}
                                    onChange={handleTimeChange}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Harvest Location</label>
                            <select name="harvestLocation" value={order.harvestLocation} onChange={handleHarvestLocationChange} className="form-control">
                                <option value="">Select Harvest Location</option>
                                {farm && farm.locations && farm.locations.map((location, index) => (
                                    <option key={index} value={location.address}>{location.name}: {location.address}</option>
                                ))}
                            </select>
                        </div>
                
                        <div className="form-group-compact">
                            <div className="form-group">
                            <label>Shipping Cost</label>
                                    <input
                                        type="text"
                                        name="shippingCost"
                                        value={order.shippingCost}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                            </div>
                            <div className="form-group">
                                <label>Unit</label>
                                <input
                                    type="text"
                                    name="productUnit"
                                    value={order.productUnit}
                                    readOnly
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Cost</label>
                                <input
                                    type="text"
                                    name="cost"
                                    value={order.cost}
                                    readOnly
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <select name="status" value={order.status} onChange={handleChange} className="form-control">
                                    <option value="Pending">Pending</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Awaiting Harvest">Awaiting Harvest</option>
                                    <option value="Harvested">Harvested</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Ready for Pickup">Ready for Pickup</option>
                                    <option value="In Transit">In Transit</option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Failed">Failed</option>
                                    <option value="On Hold">On Hold</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Total</label>
                            <input
                                type="text"
                                name="total"
                                value={order.total.toFixed(2)}
                                readOnly
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>Additional Comments</label>
                            <textarea
                                name="additionalComments"
                                value={order.additionalComments}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handleModalClose} className="cancel-button">Cancel</button>
                            <button type="submit" className="submit-button">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );    
}

export default OrderModal;
