import React from 'react';
import Sidebar from '../components/sidebar.js';
import Invoices from '../components/invoices';
import '../styles/invoices.css';

function InvoicesPage() {
    return (
        <div className="invoice-main-container">
            <div className="invoice-column-container">
                <Sidebar />
                <div className="invoice-content">
                    <Invoices />
                </div>
            </div>
        </div>
    );
}

export default InvoicesPage;
