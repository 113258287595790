import React from 'react';
import ProductList from '../components/productList.js';
import '../styles/productList.css';
import Sidebar from '../components/sidebar.js';

function ProductListPage() {
    return (
        <div className="product-list-page-container">
            <div className="productListPage-column">
                <Sidebar />
                <div className="productListPage-content">
                    <ProductList />
                </div>
            </div>
        </div>
    );
}

export default ProductListPage;
