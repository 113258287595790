import React, { createContext, useContext, useState } from 'react';

const FarmContext = createContext();

export const useFarmContext = () => useContext(FarmContext);

export const FarmProvider = ({ children }) => {
    const [farmId, setFarmId] = useState('');

    return (
        <FarmContext.Provider value={{ farmId, setFarmId }}>
            {children}
        </FarmContext.Provider>
    );
};