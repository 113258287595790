import React from 'react';
import '../styles/filterBar.css';

function CustomerFilterBar({ filters, handleFilterChange, resetFilters }) {
    return (
        <div className="filter-container">
            <input
                type="text"
                name="searchName"
                value={filters.searchName}
                onChange={handleFilterChange}
                placeholder="Search Name"
                className="filter-input"
            />
            <select name="customerType" value={filters.customerType} onChange={handleFilterChange} className="filter-select">
                <option value="All">Customer Type: All</option>
                <option value="Homeowner">Homeowner</option>
                <option value="Business">Business</option>
            </select>
            <button onClick={resetFilters} className="reset-filters">Reset</button>
        </div>
    );
}

export default CustomerFilterBar;
