import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useFarmContext } from '../contexts/farmContext';
import '../styles/shipments.css';

const Shipments = () => {
    const { farmId } = useFarmContext();
    const [orders, setOrders] = useState([]);
    const shipmentItemsRef = useRef(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const ordersRef = collection(db, 'orders');
                const today = new Date().toLocaleDateString();

                const q = query(ordersRef, where('farmId', '==', farmId));
                const querySnapshot = await getDocs(q);
                
                if (!querySnapshot.empty) {
                    const deliveryOrders = querySnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(order => {
                            const deliveryDate = order.deliveryDate ? new Date(order.deliveryDate) : null;
                            const orderDate = deliveryDate ? deliveryDate.toLocaleDateString() : null;
                            return orderDate === today && 
                                   order.deliveryAddress !== null && 
                                   ['Awaiting Harvest', 'Harvested', 'Processing', 'Ready for Pickup', 'In Transit', 'Delivered', 'On Hold'].includes(order.status);
                        });

                    console.log('Filtered orders:', deliveryOrders);
                    setOrders(deliveryOrders);
                } else {
                    console.log('No orders found for the farm.');
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };
        if (farmId) {
            fetchOrders();
        }
    }, [farmId]);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = shipmentItemsRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
            shipmentItemsRef.current.parentElement.classList.add('hide-gradient');
        } else {
            shipmentItemsRef.current.parentElement.classList.remove('hide-gradient');
        }
    };

    const getStatusClass = (status) => {
        const statusMap = {
            'Awaiting Harvest': 'awaiting-harvest',
            'Harvested': 'harvested',
            'Processing': 'processing',
            'Ready for Pickup': 'ready-for-pickup',
            'In Transit': 'in-transit',
            'Delivered': 'delivered',
            'On Hold': 'on-hold'
        };
        return statusMap[status] || 'unknown';
    };

    return (
        <div className="shipments-container">
            <h2>Shipments</h2>
            <div className="shipments-list">
                <div className="shipment-header">
                    <div className="shipment-tracking-header">Customer Name</div>
                    <div className="shipment-status-header">Status</div>
                    <div className="shipment-date-header">Shipment Date</div>
                </div>
                <div className="shipment-items" ref={shipmentItemsRef} onScroll={handleScroll}>
                    {orders.length > 0 ? (
                        orders.map(order => (
                            <div key={order.id} className="shipment-item">
                                <div className="shipment-tracking">{order.clientName || 'N/A'}</div>
                                <div className={`shipment-status ${getStatusClass(order.status)}`}>
                                    {order.status}
                                </div>
                                <div className="shipment-date">
                                    {order.deliveryDate ? new Date(order.deliveryDate).toLocaleDateString() : 'Invalid Date'}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="no-shipments">No shipments to left for today.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Shipments;