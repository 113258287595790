import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaDatabase, FaCog, FaSignOutAlt, FaUsers, FaRegListAlt } from 'react-icons/fa';
import { PiFarmFill } from "react-icons/pi";
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import '../styles/sidebar.css';
import Logo from '../imgs/Logo_V2.svg';

function Sidebar({ farmId }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); 
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'nav-item active' : 'nav-item';
    };

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <Link to="/">
                    <img src={Logo} alt="Company Logo" className="logo-image" />
                </Link>
            </div>
            <nav className="sidebar-nav">
                <ul>
                    <li>
                        <Link to="/" className={getNavLinkClass('/')}>
                            <FaHome className="nav-icon" />
                            <span className="nav-text">Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/order-list" className={getNavLinkClass('/order-list')}>
                            <FaRegListAlt className="nav-icon" />
                            <span className="nav-text">Orders</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/customer-list" className={getNavLinkClass('/customer-list')}>
                            <FaUsers className="nav-icon" />
                            <span className="nav-text">Customers</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/products-list" className={getNavLinkClass('/products-list')}>
                            <FaDatabase className="nav-icon" />
                            <span className="nav-text">Products</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/invoices' className={getNavLinkClass(`/invoices`)}>
                            <FaCog className="nav-icon" />
                            <span className="nav-text">Invoices</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/farm-settings/${farmId}`} className={getNavLinkClass(`/farm-settings/${farmId}`)}>
                            <PiFarmFill className="nav-icon" />
                            <span className="nav-text">Farm Settings</span>
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="sidebar-logout">
                <button onClick={handleLogout} className="nav-item logout-button">
                    <FaSignOutAlt className="nav-icon" />
                    <span className="nav-text">Logout</span>
                </button>
            </div>
        </div>
    );
}

export default Sidebar;
