import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../styles/productSpreadsheetView.css';

function ProductSpreadsheetView({ products, handleUpdate, handleDeleteClick }) {
    return (
        <div className="spreadsheet-view">
            <table className="spreadsheet-table">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Unit</th>
                        <th>Pricing Tier</th>
                        <th>Unit Weight</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => (
                        <tr key={product.id}>
                            <td>{product.productName}</td>
                            <td>{product.productDescription}</td>
                            <td>${product.price}</td>
                            <td>{product.productUnit}</td>
                            <td>{product.pricingTier}</td>
                            <td>{product.unitWeight} lbs</td>
                            <td>
                                <button className="icon-button" onClick={() => handleUpdate(product.id)}>
                                    <FaEdit />
                                </button>
                                <button className="icon-button" onClick={() => handleDeleteClick(product.id)}>
                                    <FaTrash />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ProductSpreadsheetView;
