import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

function UserSettings() {
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [formState, setFormState] = useState({
        email: '',
        username: '',
        farmId: '',
        role: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                try {
                    const userDoc = doc(db, 'users', user.uid);
                    const userDocSnapshot = await getDoc(userDoc);
                    if (userDocSnapshot.exists()) {
                        const data = userDocSnapshot.data();
                        setUserData(data);
                        setFormState({
                            email: data.email,
                            username: data.username,
                            farmId: data.farmId,
                            role: data.role
                        });
                    } else {
                        console.log('No such user document!');
                    }
                } catch (err) {
                    console.error('Error fetching user data:', err);
                }
            }
        };

        fetchUserData();
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user) {
            try {
                const userDoc = doc(db, 'users', user.uid);
                await updateDoc(userDoc, {
                    email: formState.email,
                    username: formState.username
                });
                setUserData(formState); // Update local state to reflect changes
                alert('User data updated successfully!');
            } catch (err) {
                console.error('Error updating user data:', err);
                alert('Failed to update user data. Please try again.');
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (!userData) {
        return <p>No user data found.</p>;
    }

    return (
        <div className="container user-settings-form">
            <h2 className="form-title">User Settings</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="full-width">
                        Email:
                        <input
                            type="text"
                            name="email"
                            value={formState.email}
                            className="form-control"
                            onChange={handleChange}
                        />
                    </label>
                    <label className="full-width">
                        Username:
                        <input
                            type="text"
                            name="username"
                            value={formState.username}
                            className="form-control"
                            onChange={handleChange}
                        />
                    </label>
                    <label className="full-width">
                        Farm ID:
                        <input
                            type="text"
                            name="farmId"
                            value={formState.farmId}
                            className="form-control"
                            disabled
                        />
                    </label>
                    <label className="full-width">
                        Role:
                        <input
                            type="text"
                            name="role"
                            value={formState.role}
                            className="form-control"
                            disabled
                        />
                    </label>
                    <button type="submit" className="submit-button">Save</button>
                </div>
            </form>
        </div>
    );
}

export default UserSettings;
