import React, { useState, useEffect, useCallback } from 'react';
import { format, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subMonths, isWithinInterval, parseISO } from 'date-fns';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Modal from './modal';
import OrderModal from './orderModal';
import UpdateOrderModal from './updateOrderModal';
import { useFarmContext } from '../contexts/farmContext';
import FilterBar from './filterBar';
import OrderCard from './orderCard';
import SpreadsheetView from './orderSpreadsheetView';
import { FaTh, FaList, FaPlus } from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../styles/orderList.css';

function OrderList() {
    const { farmId } = useFarmContext();
    const [orders, setOrders] = useState([]);
    const [weatherData, setWeatherData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState(null);
    const [activeTab, setActiveTab] = useState('today');
    const [filters, setFilters] = useState({
        searchName: '',
        location: 'All',
        product: 'All',
        date: 'All',
    });
    const [locations, setLocations] = useState([]);
    const [products, setProducts] = useState([]);
    const [isCardView, setIsCardView] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersPerPage, setOrdersPerPage] = useState(15);

    // Fetch orders from Firestore
    const fetchOrders = useCallback(async () => {
        try {
            const ordersRef = collection(db, 'orders');
            const q = query(ordersRef, where('farmId', '==', farmId));
            const querySnapshot = await getDocs(q);
            const ordersData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    deliveryDate: typeof data.deliveryDate === 'string' ? parseISO(data.deliveryDate) : null,
                    pickupDate: typeof data.pickupDate === 'string' ? parseISO(data.pickupDate) : null,
                    harvestDate: typeof data.harvestDate === 'string' ? parseISO(data.harvestDate) : null
                };
            });
            setOrders(ordersData);
            if (activeTab === 'today') {
                fetchWeatherDataForOrders(ordersData);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }, [farmId, activeTab]);

    // Fetch farm locations and products
    const fetchFarmData = useCallback(async () => {
        try {
            const farmDocRef = doc(db, 'farms', farmId);
            const farmDoc = await getDoc(farmDocRef);
            if (farmDoc.exists()) {
                setLocations(farmDoc.data().locations || []);
            }
            const pricesRef = collection(db, 'products');
            const pricesQuery = query(pricesRef, where('farmId', '==', farmId));
            const pricesSnapshot = await getDocs(pricesQuery);
            const productsData = pricesSnapshot.docs.map(doc => doc.data().productName);
            setProducts(productsData || []);

            console.log("Fetched Products in OrderList: ", productsData);  // Debugging line

        } catch (error) {
            console.error('Error fetching farm data:', error);
        }
    }, [farmId]);

    useEffect(() => {
        if (farmId) {
            fetchOrders();
            fetchFarmData();
            const interval = setInterval(fetchOrders, 60000); // Re-fetch orders every minute
            return () => clearInterval(interval);
        }
    }, [fetchOrders, fetchFarmData, farmId]);

    const handleDeleteClick = (id) => {
        setCurrentOrderId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentOrderId(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteDoc(doc(db, 'orders', currentOrderId));
            setOrders(orders.filter(order => order.id !== currentOrderId));
            handleCloseModal();
        } catch (error) {
            console.error('Error deleting order:', error);
        }
    };

    const handleUpdate = (orderId) => {
        setCurrentOrderId(orderId);
        setShowUpdateOrderModal(true);
    };

    const fetchWeatherDataForOrders = async (orders) => {
        const weatherDataTemp = {};
        for (let order of orders) {
            const harvestDate = order.harvestDate;
            const today = new Date();

            if ((harvestDate - today) / (1000 * 60 * 60 * 24) > 7) {
                weatherDataTemp[order.id] = 'Unavailable';
            } else {
                const city = order.harvestLocation.split(',')[1]?.trim() || order.harvestLocation.split(',')[0];
                try {
                    const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}&units=metric`);
                    const data = await response.json();
                    weatherDataTemp[order.id] = data.weather[0].description;
                } catch (error) {
                    console.error('Error fetching weather data:', error);
                    weatherDataTemp[order.id] = 'Error fetching weather';
                }
            }
        }
        setWeatherData(weatherDataTemp);
    };

    const getWeatherStyle = (description) => {
        if (description.includes('clear')) return 'clear-weather';
        if (description.includes('cloud')) return 'partly-cloudy-weather';
        if (description.includes('rain')) return 'rainy-weather';
        return '';
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const resetFilters = () => {
        setFilters({
            searchName: '',
            location: 'All',
            product: 'All',
            date: 'All',
        });
    };

    const formatDateTime = (date) => {
        return date ? format(date, 'MM-dd-yyyy | hh:mm a') : 'N/A';
    };

    const handleOrdersPerPageChange = (e) => {
        setOrdersPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * ordersPerPage;
        const endIndex = startIndex + ordersPerPage;
        return displayOrders.slice(startIndex, endIndex);
    };

    const renderPageNumbers = () => {
        const totalPages = Math.ceil(displayOrders.length / ordersPerPage);
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`page-number ${currentPage === i ? 'active' : ''}`}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    const filteredOrders = orders.filter(order => {
        const orderDate = order.harvestDate;
        const today = new Date();
        const lastMonth = subMonths(today, 1);
        const isDateMatch = filters.date === 'All'
            || (filters.date === 'Today' && isWithinInterval(orderDate, { start: startOfDay(today), end: endOfDay(today) }))
            || (filters.date === 'ThisWeek' && isWithinInterval(orderDate, { start: startOfWeek(today), end: endOfWeek(today) }))
            || (filters.date === 'ThisMonth' && isWithinInterval(orderDate, { start: startOfMonth(today), end: endOfMonth(today) }))
            || (filters.date === 'LastMonth' && isWithinInterval(orderDate, { start: startOfMonth(lastMonth), end: endOfMonth(lastMonth) }));
        const isLocationMatch = filters.location === 'All' || order.harvestLocation.includes(filters.location);
        const isProductMatch = filters.product === 'All' || order.productName === filters.product;
        const isSearchNameMatch = filters.searchName === '' || order.clientName.toLowerCase().includes(filters.searchName.toLowerCase());
        return isDateMatch && isLocationMatch && isProductMatch && isSearchNameMatch;
    });

    const todayOrders = orders.filter(order => {
        const today = new Date();
        return isWithinInterval(order.harvestDate, { start: startOfDay(today), end: endOfDay(today) });
    }).filter(order => {
        const isLocationMatch = filters.location === 'All' || order.harvestLocation.includes(filters.location);
        const isProductMatch = filters.product === 'All' || order.productName === filters.product;
        const isSearchNameMatch = filters.searchName === '' || order.clientName.toLowerCase().includes(filters.searchName.toLowerCase());
        return isLocationMatch && isProductMatch && isSearchNameMatch;
    });

    const displayOrders = activeTab === 'today' ? todayOrders : filteredOrders;

    const status = async (orderId, newStatus) => {
        try {
            await updateDoc(doc(db, 'orders', orderId), { status: newStatus });
            fetchOrders(); // Refresh order list after updating status
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const handleGenerateInvoice = (order) => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        doc.setFontSize(24);
        doc.setTextColor(44, 62, 80);
        doc.text('INVOICE', pageWidth - 14, 20, { align: 'right'});

        doc.setFontSize(10);
        doc.setTextColor(52, 73, 94);
        doc.text([
            'Your Farm Name',
            '123 Farm Road',
            'Farmville, State 12345',
            'Phone: (123) 456-7890',
            'Email: info@yourfarm.com'
        ], 14, 30);

        doc.text([
            `Client: ${order.clientName}`,
            `Invoice Date: ${new Date().toLocaleDateString()}`,
            `Delivery Date: ${new Date(order.deliveryDate).toLocaleDateString()}`,
            `Invoice Number: INV-${order.id.toString().padStart(6, '0')}`
        ], pageWidth - 14, 30, { align: 'right' });
    
        doc.autoTable({
            startY: 70,
            head: [['Description', 'Quantity', 'Unit Price', 'Total']],
            body: [
                [
                    order.productName,
                    order.amount.toString(),
                    `$${parseFloat(order.cost).toFixed(2)}`,
                    `$${(order.amount * order.cost).toFixed(2)}`
                ],
                ['Shipping', '', '', `$${parseFloat(order.shippingCost || 0).toFixed(2)}`]
            ],
            foot: [['', '', 'Total', `$${(order.amount * order.cost + parseFloat(order.shippingCost || 0)).toFixed(2)}`]],
            theme: 'striped',
            headStyles: { fillColor: [44, 62, 80], textColor: 255 },
            footStyles: { fillColor: [44, 62, 80], textColor: 255 },
            alternateRowStyles: { fillColor: [241, 245, 249] }
        });
    
        doc.setFontSize(10);
        doc.text('Payment Terms: Due within 30 days', 14, doc.autoTable.previous.finalY + 20);
        doc.text('Thank you for your business!', 14, doc.autoTable.previous.finalY + 30);
    
        doc.line(14, pageHeight - 40, 90, pageHeight - 40);
        doc.text('Employee Signature', 14, pageHeight - 35);
    
        doc.line(pageWidth - 90, pageHeight - 40, pageWidth - 14, pageHeight - 40);
        doc.text('Customer Signature', pageWidth - 90, pageHeight - 35);
    
        doc.save(`Invoice_${order.clientName}_${new Date().toISOString()}.pdf`);

            }

    return (
        <div className="order-list-container">
            <div className="header">
                <h2>Order Management</h2>
                <div className="header-controls">
                    <div className="view-buttons-container">
                        <div className="view-buttons">
                            <button onClick={() => setIsCardView(true)} className={isCardView ? 'active' : ''}>
                                <FaTh />
                            </button>
                            <button onClick={() => setIsCardView(false)} className={!isCardView ? 'active' : ''}>
                                <FaList />
                            </button>
                            <button onClick={() => setShowOrderModal(true)} className="create-button">
                                <FaPlus /> Create Order
                            </button>
                        </div>
                    </div>
                    <div className="pagination-controls">
                        <label>
                            Orders per page:
                            <select value={ordersPerPage} onChange={handleOrdersPerPageChange}>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div className="tabs">
                <div className={`tab ${activeTab === 'today' ? 'active' : ''}`} onClick={() => setActiveTab('today')}>Today's Orders</div>
                <div className={`tab ${activeTab === 'history' ? 'active' : ''}`} onClick={() => setActiveTab('history')}>Order History</div>
            </div>
            <FilterBar
                filters={filters}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
                locations={locations}
                products={products}
                showDateFilter={activeTab !== 'today'}
            />
            <div className="order-views">
                {isCardView ? (
                    <div className="order-cards">
                        {getPaginatedData().length > 0 ? (
                            getPaginatedData().map(order => (
                                <OrderCard
                                    key={order.id}
                                    order={{
                                        ...order,
                                        formattedDeliveryDate: formatDateTime(order.deliveryDate),
                                        formattedPickupDate: formatDateTime(order.pickupDate),
                                        formattedHarvestDate: formatDateTime(order.harvestDate),
                                    }}
                                    weatherData={weatherData}
                                    handleUpdate={handleUpdate}
                                    handleDeleteClick={handleDeleteClick}
                                    getWeatherStyle={getWeatherStyle}
                                    showWeather={activeTab === 'today'}
                                    buttonClass="button"
                                    status={status}
                                />
                            ))
                        ) : (
                            <p>No orders found.</p>
                        )}
                    </div>
                ) : (
                    <SpreadsheetView
                    orders={getPaginatedData().map(order => ({
                        ...order,
                        formattedDeliveryDate: formatDateTime(order.deliveryDate),
                        formattedPickupDate: formatDateTime(order.pickupDate),
                        formattedHarvestDate: formatDateTime(order.harvestDate),
                    }))}
                    weatherData={weatherData}
                    handleUpdate={handleUpdate}
                    handleDeleteClick={handleDeleteClick}
                    status={status}
                    handleGenerateInvoice={handleGenerateInvoice}
                />
                )}
            </div>
            <div className="pagination">
                {renderPageNumbers()}
            </div>
            <Modal
                show={showModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this order? This action cannot be undone."
            />
            <OrderModal
                show={showOrderModal}
                onClose={() => setShowOrderModal(false)}
                fetchOrders={fetchOrders}
            />
            <UpdateOrderModal
                show={showUpdateOrderModal}
                onClose={() => setShowUpdateOrderModal(false)}
                orderId={currentOrderId}
                fetchOrders={fetchOrders}
            />
        </div>
    );
}

export default OrderList;
