import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useFarmContext } from '../contexts/farmContext';

const PrivateRoute = ({ children }) => {
    const [user, loading] = useAuthState(auth);
    const { setFarmId } = useFarmContext();
    const [userDataLoading, setUserDataLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (user) {
                    const userDoc = doc(db, 'users', user.uid);
                    const userDocSnapshot = await getDoc(userDoc);
                    if (userDocSnapshot.exists()) {
                        const userData = userDocSnapshot.data();
                        setFarmId(userData.farmId); // Store farmId in context
                    } else {
                        console.log('No such user document!');
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setUserDataLoading(false);
            }
        };

        fetchUserData();
    }, [user, setFarmId]);

    if (loading || userDataLoading) return <p>Loading...</p>; // Loading state while checking authentication and fetching user data
    return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
