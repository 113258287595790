import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../styles/customerCard.css'; // Use appropriate styles for CustomerCard

function CustomerCard({ customer, handleUpdate, handleDeleteClick }) {
    return (
            <div className="customer-card" onClick={() => handleUpdate(customer.id)}>
                <div className="customer-card-header">
                    <h3>Customer: {`${customer.firstName} ${customer.lastName}`}</h3>
                    <div className="customer-actions">
                        <button className="icon-button" onClick={() => handleUpdate(customer.id)}>
                            <FaEdit />
                        </button>
                        <button className="icon-button" onClick={() => handleDeleteClick(customer.id)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
                <div className="customer-card-body">
                    <p>Email: {customer.email}</p>
                    <p>Phone: {customer.phone}</p>
                    <p>Customer Type: {customer.type}</p>
                    <p>Address: {customer.address}</p>
                </div>
            </div>
    );
}

export default CustomerCard;
