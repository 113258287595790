import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyD6XOZAoSsbHiLP_Q-oWa-gijH1VGxMdok",
  authDomain: "terrace-4bb52.firebaseapp.com",
  projectId: "terrace-4bb52",
  storageBucket: "terrace-4bb52.appspot.com",
  messagingSenderId: "964593450494",
  appId: "1:964593450494:web:48f06b02991a14625bd4af",
  measurementId: "G-CGRMMJF9GL"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
