import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Assuming you have a Firebase config file
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { useFarmContext } from '../contexts/farmContext';
import { startOfToday, endOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import '../styles/overview.css';

Chart.register(ArcElement, Tooltip, Legend);

const Overview = () => {
    const { farmId } = useFarmContext();
    const [orderData, setOrderData] = useState({
        awaitingHarvest: 0,
        harvested: 0,
        processing: 0,
        readyForPickup: 0,
        inTransit: 0,
        delivered: 0,
        completed: 0,
    });
    const [timeSpan, setTimeSpan] = useState('today');

    const fetchOrderData = useCallback(async () => {
        try {
            const ordersRef = collection(db, 'orders');
            const q = query(ordersRef, where('farmId', '==', farmId));
            const querySnapshot = await getDocs(q);

            const today = new Date();
            const data = querySnapshot.docs.reduce((acc, doc) => {
                const order = doc.data();
                // Handle date as ISO string
                const harvestDate = order.harvestDate ? new Date(order.harvestDate) : null;

                let isInTimeSpan = false;
                if (harvestDate) {
                    switch (timeSpan) {
                        case 'today':
                            isInTimeSpan = harvestDate >= startOfToday() && harvestDate <= endOfToday();
                            break;
                        case 'thisWeek':
                            isInTimeSpan = harvestDate >= startOfWeek(today) && harvestDate <= endOfWeek(today);
                            break;
                        case 'thisMonth':
                            isInTimeSpan = harvestDate >= startOfMonth(today) && harvestDate <= endOfMonth(today);
                            break;
                        case 'thisYear':
                            isInTimeSpan = harvestDate >= startOfYear(today) && harvestDate <= endOfYear(today);
                            break;
                        default:
                            isInTimeSpan = false;
                    }
                }

                if (isInTimeSpan) {
                    acc.totalOrders += 1;
                    const status = order.status.replace(' ', '').toLowerCase();
                    if (status === 'awaitingharvest') {
                        acc.awaitingHarvest += 1;
                    } else if (status === 'harvested') {
                        acc.harvested += 1;
                    } else if (status === 'processing') {
                        acc.processing += 1;
                    } else if (status === 'readyforpickup') {
                        acc.readyForPickup += 1;
                    } else if (status === 'intransit') {
                        acc.inTransit += 1;
                    } else if (status === 'delivered') {
                        acc.delivered += 1;
                    } else if (status === 'completed') {
                        acc.completed += 1;
                    }
                }
                return acc;
            }, {
                totalOrders: 0,
                awaitingHarvest: 0,
                harvested: 0,
                processing: 0,
                readyForPickup: 0,
                inTransit: 0,
                delivered: 0,
                completed: 0,
            });
            setOrderData(data);
        } catch (error) {
            console.error('Error fetching order data:', error);
        }
    }, [farmId, timeSpan]);

    useEffect(() => {
        fetchOrderData();
    }, [fetchOrderData]);

    const isEmptyData = Object.values(orderData).every(value => value === 0);

    const data = {
        labels: ['Awaiting Harvest', 'Harvested', 'Processing', 'Ready for Pickup', 'In Transit', 'Delivered', 'Completed'],
        datasets: [
            {
                label: 'Orders',
                data: isEmptyData ? [1, 1, 1, 1, 1, 1, 1] : [
                    orderData.awaitingHarvest,
                    orderData.harvested,
                    orderData.processing,
                    orderData.readyForPickup,
                    orderData.inTransit,
                    orderData.delivered,
                    orderData.completed,
                ],
                backgroundColor: [
                    '#A3BE8C', // Light green
                    '#88C0D0', // Muted blue
                    '#EBCB8B', // Muted yellow
                    '#D08770', // Soft orange
                    '#5E81AC', // Desaturated blue
                    '#B48EAD', // Soft purple
                    '#4C566A', // Muted gray
                ],
                borderColor: [
                    '#A3BE8C',
                    '#88C0D0',
                    '#EBCB8B',
                    '#D08770',
                    '#5E81AC',
                    '#B48EAD',
                    '#4C566A',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="overview-container">
            <h2 className="overview-title">Orders Overview</h2>
            <div className="time-span-selector">
                <select
                    id="time-span"
                    className="time-span-select"
                    value={timeSpan}
                    onChange={(e) => setTimeSpan(e.target.value)}
                >
                    <option value="today">Today</option>
                    <option value="thisWeek">This Week</option>
                    <option value="thisMonth">This Month</option>
                    <option value="thisYear">This Year</option>
                </select>
            </div>
            <div className="overview-chart">
                <div className="chart-container">
                    <Pie data={data} options={{ maintainAspectRatio: false, plugins: { legend: { display: false } } }} />
                </div>
                <div className="chart-legend">
                    {data.labels.map((label, index) => (
                        <div key={index} className="legend-item">
                            <div className="legend-color-box" style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}></div>
                            <span>{label}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Overview;
