import React, { useEffect, useState, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useFarmContext } from '../contexts/farmContext';
import '../styles/welcome.css';

const API_KEY = 'ab5a6ef48a2260d28992bfb7f933c14d';

const Welcome = () => {
    const [user] = useAuthState(auth);
    const { farmId } = useFarmContext();
    const [userName, setUserName] = useState('');
    const [greeting, setGreeting] = useState('');
    const [orders, setOrders] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [isOverflow, setIsOverflow] = useState(false);
    const [harvestEstimation, setHarvestEstimation] = useState('');
    const [harvestChance, setHarvestChance] = useState(0);

    const fetchUserName = useCallback(async () => {
        if (user) {
            try {
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    setUserName(userDoc.data().username);
                }
            } catch (error) {
                console.error('Error fetching user name:', error);
            }
        }
    }, [user]);

    const fetchOrders = useCallback(async () => {
        if (!farmId) return;

        try {
            const q = query(
                collection(db, 'orders'),
                where('farmId', '==', farmId)
            );

            const querySnapshot = await getDocs(q);
            const todayOrders = [];
            let revenue = 0;

            const today = new Date();
            today.setHours(0, 0, 0, 0); // Start of today
            const endOfToday = new Date(today.getTime() + 86400000); // End of today

            querySnapshot.forEach((doc) => {
                const orderData = doc.data();
                const harvestDate = new Date(orderData.harvestDate);
                
                if (harvestDate >= today && harvestDate < endOfToday) {
                    todayOrders.push({ id: doc.id, ...orderData });
                    revenue += parseFloat(orderData.total || 0);
                }
            });

            setOrders(todayOrders);
            setTotalRevenue(revenue);
            checkOverflow(todayOrders.length, revenue);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }, [farmId]);

    const checkOverflow = (orderCount, revenue) => {
        const totalDigits = orderCount.toString().length + revenue.toFixed(2).replace('.', '').length;
        setIsOverflow(totalDigits > 9);
    };

    const determineGreeting = useCallback(() => {
        const currentHour = new Date().getHours();
        if (currentHour >= 4 && currentHour < 12) {
            setGreeting('Good morning');
        } else if (currentHour >= 12 && currentHour < 17) {
            setGreeting('Good afternoon');
        } else {
            setGreeting('Good evening');
        }
    }, []);

    const fetchWeatherData = useCallback(async () => {
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=33.7488&lon=-84.3877&appid=${API_KEY}`);
            const data = await response.json();
            estimateHarvestAbility(data.list);
        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    }, []);

    const estimateHarvestAbility = (weatherData) => {
        let totalRain = 0;
        weatherData.forEach(entry => {
            if (entry.weather[0].main === 'Rain') {
                totalRain += entry.rain ? entry.rain['3h'] || 0 : 0;
            }
        });

        if (totalRain > 20) {
            setHarvestEstimation('Poor');
            setHarvestChance(30);
        } else if (totalRain > 10) {
            setHarvestEstimation('Fair');
            setHarvestChance(60);
        } else {
            setHarvestEstimation('Good');
            setHarvestChance(90);
        }
    };

    useEffect(() => {
        fetchUserName();
        determineGreeting();
        if (user) {
            fetchOrders();
            fetchWeatherData();
        }
    }, [fetchUserName, determineGreeting, fetchOrders, fetchWeatherData, user]);

    return (
        <div className="welcome-container">
            <div className="welcome-message">
                <h2>{greeting}, {userName || 'Guest'}</h2>
                <p className="welcome-overview">Here's a quick overview of your orders and deadlines for today.</p>
            </div>
            <div className="orders-deadlines-container">
                <div className="orders-deadlines">
                    <div className={`orders-revenue ${isOverflow ? 'overflow' : ''}`}>
                        <div className="orders">
                            <h3>{orders.length}</h3>
                            <p>Orders</p>
                        </div>
                        <div className="revenue">
                            <h3>${totalRevenue.toFixed(2)}</h3>
                            <p>Revenue</p>
                        </div>
                    </div>
                    <div className="orders-list">
                        <ul>
                            {orders.map(order => (
                                <li key={order.id}>{order.description || 'No description available'}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="notifications">
                    <h3>Notifications</h3>
                    <ul>
                        <li>This feature has temporarily been disabled</li>
                    </ul>
                    <h3>Harvest Estimation</h3>
                    <ul>
                        <li>This feature has temporarily been disabled</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
