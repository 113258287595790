import React from 'react';
import Overview from '../components/overview';
import Shipments from '../components/shipments';
import Sidebar from '../components/sidebar';
import Welcome from '../components/welcome';
import Weather from '../components/weather';
import '../styles/dashboard.css';

function DashboardPage() {
    return (
        <div className="dashboard-main-container">
            <Sidebar />
            <div className="dashboard-grid-container">
                <div className="dashboard-welcome card">
                    <Welcome />
                </div>
                <div className="dashboard-chart card">
                    <Overview />
                </div>
                <div className="dashboard-weather card wide-card">
                    <Weather />
                </div>
                <div className="dashboard-shipments card">
                    <Shipments />
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;
