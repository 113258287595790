import React from 'react';
import Sidebar from '../components/sidebar.js';
import CustomerList from '../components/customerList';
import '../styles/customerList.css';

function CustomerListPage() {
    return (
        <div className="customer-main-container">
            <div className="customer-column-container">
                <Sidebar />
                <div className="customerList-content">
                    <CustomerList />
                </div>
            </div>
        </div>
    );
}

export default CustomerListPage;
