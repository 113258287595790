import React from 'react';
import Sidebar from '../components/sidebar.js';
import FarmSettings from '../components/farmSettings';
import '../styles/farmSettings.css'

function FarmSettingsPage() {
    return (
        <div className="farmSettings-main-container">
            <div className="farmSettings-column-container">
                <Sidebar />
                <div className="farmSettings-content">
                    <FarmSettings />
                </div>
            </div>
        </div>
    );
}

export default FarmSettingsPage;
