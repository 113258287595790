// Invoices.js
import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useFarmContext } from '../contexts/farmContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { FaSearch, FaFileInvoice } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/invoices.css';

const Invoices = () => {
  const { farmId } = useFarmContext();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState({ 
    customerName: '', 
    productType: '', 
    documentType: 'invoice',
    timePeriod: 'custom'
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [reportFileType, setReportFileType] = useState('pdf');

  const fetchOrders = useCallback(async () => {
    if (!farmId) {
      console.error('Farm ID is required');
      return;
    }
    try {
      const ordersQuery = query(
        collection(db, 'orders'),
        where('farmId', '==', farmId)
      );
      const querySnapshot = await getDocs(ordersQuery);
      const ordersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrders(ordersData);
      setFilteredOrders(ordersData);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  }, [farmId]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'timePeriod') {
      setDateRange(getDateRangeForPeriod(value));
    }
  };

  const getDateRangeForPeriod = (period) => {
    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    let startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    
    switch (period) {
      case 'today':
        break;
      case 'weekly':
        startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - 6);
        break;
      case 'monthly':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        break;
      case 'quarterly':
        startDate = new Date(endDate.getFullYear(), Math.floor(endDate.getMonth() / 3) * 3, 1);
        break;
      case 'yearly':
        startDate = new Date(endDate.getFullYear(), 0, 1);
        break;
      default:
        return [null, null];
    }
    
    return [startDate, endDate];
  };

  const filterOrders = useCallback(() => {
    let filtered = orders;
    if (search.customerName) {
      filtered = filtered.filter(order => 
        order.clientName.toLowerCase().includes(search.customerName.toLowerCase())
      );
    }
    if (search.productType) {
      filtered = filtered.filter(order => 
        order.productName.toLowerCase().includes(search.productType.toLowerCase())
      );
    }
    if (startDate && endDate) {
      filtered = filtered.filter(order => {
        const orderDate = new Date(order.deliveryDate);
        return orderDate >= startDate && orderDate <= endDate;
      });
    }
    setFilteredOrders(filtered);
  }, [orders, search, startDate, endDate]);

  useEffect(() => {
    filterOrders();
  }, [filterOrders]);

  const formatCurrency = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '0.00' : number.toFixed(2);
  };

  const generatePDFInvoice = useCallback(() => {
    if (!selectedOrder) return;

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(18);
    doc.text('INVOICE', pageWidth - 14, 20, { align: 'right' });

    doc.setFontSize(10);
    doc.text([
      'Your Farm Name',
      '123 Farm Road',
      'Farmville, State 12345',
      'Phone: (123) 456-7890',
      'Email: info@yourfarm.com'
    ], 14, 30);

    doc.text([
      `Client: ${selectedOrder.clientName}`,
      `Invoice Date: ${new Date().toLocaleDateString()}`,
      `Delivery Date: ${new Date(selectedOrder.deliveryDate).toLocaleDateString()}`,
      `Invoice Number: INV-${selectedOrder.id.toString().padStart(6, '0')}`
    ], pageWidth - 14, 30, { align: 'right' });

    doc.autoTable({
      startY: 70,
      head: [['Description', 'Quantity', 'Unit Price', 'Total']],
      body: [
        [
          selectedOrder.productName,
          selectedOrder.amount.toString(),
          `$${formatCurrency(selectedOrder.cost)}`,
          `$${formatCurrency(selectedOrder.amount * selectedOrder.cost)}`
        ],
        ['Shipping', '', '', `$${formatCurrency(selectedOrder.shippingCost)}`]
      ],
      foot: [['', '', 'Total', `$${formatCurrency(parseFloat(selectedOrder.shippingCost) + (selectedOrder.amount * selectedOrder.cost))}`]],
      theme: 'striped',
      headStyles: { fillColor: [44, 62, 80], textColor: 255 },
      footStyles: { fillColor: [44, 62, 80], textColor: 255 },
    });

    doc.save(`Invoice_${selectedOrder.clientName}_${new Date().toISOString()}.pdf`);
  }, [selectedOrder]);

  const generatePDFReport = useCallback(() => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(18);
    doc.text('Sales Report', pageWidth / 2, 20, { align: 'center' });

    doc.setFontSize(10);
    doc.text(`Date Range: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`, pageWidth / 2, 30, { align: 'center' });

    doc.autoTable({
      startY: 40,
      head: [['Date', 'Customer', 'Product', 'Quantity', 'Total']],
      body: filteredOrders.map((order) => [
        new Date(order.deliveryDate).toLocaleDateString(),
        order.clientName,
        order.productName,
        order.amount,
        `$${formatCurrency(order.total)}`
      ]),
      foot: [['', '', '', 'Grand Total', `$${formatCurrency(filteredOrders.reduce((sum, order) => sum + parseFloat(order.total), 0))}`]],
      theme: 'striped',
      headStyles: { fillColor: [44, 62, 80], textColor: 255 },
      footStyles: { fillColor: [44, 62, 80], textColor: 255 },
    });

    doc.save(`Sales_Report_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.pdf`);
  }, [filteredOrders, startDate, endDate]);

  const getCSVData = useCallback(() => [
    ['Date', 'Customer', 'Product', 'Quantity', 'Unit Price', 'Shipping Cost', 'Total'],
    ...filteredOrders.map((order) => [
      new Date(order.deliveryDate).toLocaleDateString(),
      order.clientName,
      order.productName,
      order.amount,
      formatCurrency(order.cost),
      formatCurrency(order.shippingCost),
      formatCurrency(order.total),
    ]),
  ], [filteredOrders]);

  const generateReport = useCallback(() => {
    if (reportFileType === 'pdf') {
      generatePDFReport();
    } else {
      // Trigger CSV download
      document.getElementById('csvLink').click();
    }
  }, [reportFileType, generatePDFReport]);

  return (
    <div className="invoices-reports-container">
      <div className="filters-section">
        <h2>Invoices & Reports</h2>
        <div className="search-filters">
          <input
            type="text"
            name="customerName"
            placeholder="Customer Name"
            value={search.customerName}
            onChange={handleSearchChange}
          />
          <input
            type="text"
            name="productType"
            placeholder="Product Type"
            value={search.productType}
            onChange={handleSearchChange}
          />
          <select
            name="documentType"
            value={search.documentType}
            onChange={handleSearchChange}
          >
            <option value="invoice">Invoice</option>
            <option value="report">Report</option>
          </select>
          <select
            name="timePeriod"
            value={search.timePeriod}
            onChange={handleSearchChange}
          >
            <option value="custom">Custom</option>
            <option value="today">Today</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="yearly">Yearly</option>
          </select>
          {search.timePeriod === 'custom' && (
            <div className="date-picker-filter">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                placeholderText="Select date range"
                className="date-picker"
              />
            </div>
          )}
          <button onClick={filterOrders} className="search-button">
            <FaSearch /> Search
          </button>
        </div>
      </div>
      <div className="content-section">
        <div className="orders-list">
          {filteredOrders.map((order) => (
            <div
              key={order.id}
              className="order-item"
              onClick={() => setSelectedOrder(order)}
            >
              <p className="customer-name">{order.clientName}</p>
              <p className="order-date">{new Date(order.deliveryDate).toLocaleDateString()}</p>
              <p className="order-total">${formatCurrency(order.total)}</p>
            </div>
          ))}
        </div>
        <div className="document-preview">
          {search.documentType === 'invoice' ? (
            selectedOrder ? (
              <div className="invoice-details">
                <h2>Invoice for Order: {selectedOrder.id}</h2>
                <table>
                  <tbody>
                    <tr><th>Client Name</th><td>{selectedOrder.clientName}</td></tr>
                    <tr><th>Delivery Date</th><td>{new Date(selectedOrder.deliveryDate).toLocaleDateString()}</td></tr>
                    <tr><th>Product Name</th><td>{selectedOrder.productName}</td></tr>
                    <tr><th>Amount</th><td>{selectedOrder.amount} {selectedOrder.productUnit}</td></tr>
                    <tr><th>Cost per Unit</th><td>${formatCurrency(selectedOrder.cost)}</td></tr>
                    <tr><th>Shipping Cost</th><td>${formatCurrency(selectedOrder.shippingCost)}</td></tr>
                    <tr><th>Total Cost</th><td>${formatCurrency(parseFloat(selectedOrder.shippingCost) + (selectedOrder.amount * selectedOrder.cost))}</td></tr>
                  </tbody>
                </table>
                <button className="export-button" onClick={generatePDFInvoice}>
                  <FaFileInvoice /> Download PDF
                </button>
              </div>
            ) : (
              <p className="select-order-message">Select an order to create an invoice</p>
            )
          ) : (
            <div className="report-preview">
              <h3>Sales Report</h3>
              <p>Date Range: {startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()}</p>
              <p>Total Orders: {filteredOrders.length}</p>
              <p>Total Revenue: ${formatCurrency(filteredOrders.reduce((sum, order) => sum + parseFloat(order.total), 0))}</p>
              <div className="export-options">
                <select
                  value={reportFileType}
                  onChange={(e) => setReportFileType(e.target.value)}
                  className="file-type-selector"
                >
                  <option value="pdf">PDF</option>
                  <option value="csv">CSV</option>
                </select>
                <button className="export-button" onClick={generateReport}>
                  Generate Report
                </button>
                <CSVLink
                  id="csvLink"
                  data={getCSVData()}
                  filename={`Sales_Report_${startDate?.toISOString().split('T')[0]}_${endDate?.toISOString().split('T')[0]}.csv`}
                  style={{display: 'none'}}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;