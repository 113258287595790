import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../styles/productCard.css';

function ProductCard({ product, handleUpdate, handleDeleteClick }) {
    return (
        <div className="product-card" onClick={() => handleUpdate(product.id)}>
            <div className="product-card-header">
                <h3>Product: {product.productName}</h3>
                <div className="product-actions">
                    <button className="icon-button" onClick={() => handleUpdate(product.id)}>
                        <FaEdit />
                    </button>
                    <button className="icon-button" onClick={() => handleDeleteClick(product.id)}>
                        <FaTrash />
                    </button>
                </div>
            </div>
            <div className="product-card-body">
                <p>Description: {product.productDescription}</p>
                <p>Price: ${product.price}</p>
                <p>Unit: {product.productUnit}</p>
                <p>Pricing Tier: {product.pricingTier}</p>
                <p>Unit Weight: {product.unitWeight} lbs</p>
            </div>
        </div>
    );
}

export default ProductCard;
