import React from 'react';
import PropTypes from 'prop-types';
import '../styles/customerModal.css';

function CustomerModal({ show, onClose, onSubmit, customer, handleInputChange }) {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Create New Customer</h2>
                    <button onClick={onClose} className="close-button">&times;</button>
                </div>
                <div className="modal-body">
                    <form className="customer-form" onSubmit={onSubmit}>
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={customer.firstName}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={customer.lastName}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                value={customer.email}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input
                                type="text"
                                name="phone"
                                value={customer.phone}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input
                                type="text"
                                name="address"
                                value={customer.address}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Type</label>
                            <select
                                name="type"
                                value={customer.type}
                                onChange={handleInputChange}
                                className="form-control"
                                required
                            >
                                <option value="">Select Type</option>
                                <option value="Homeowner">Homeowner</option>
                                <option value="Business">Business</option>
                            </select>
                        </div>
                        {customer.type === 'Business' && (
                            <div className="form-group">
                                <label>Business Name</label>
                                <input
                                    type="text"
                                    name="businessName"
                                    value={customer.businessName}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        )}
                        <div className="modal-footer">
                            <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
                            <button type="submit" className="submit-button">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

CustomerModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
};

export default CustomerModal;
