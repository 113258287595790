import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebaseConfig';
import { doc, collection, getDocs, addDoc, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import ProductModal from './productModal';
import UpdateProductModal from './updateProductModal';
import Modal from './modal';
import { useFarmContext } from '../contexts/farmContext';
import ProductFilterBar from './productFilterBar';
import ProductCard from './productCard';
import ProductSpreadsheetView from './productSpreadsheetView';
import { FaTh, FaList, FaPlus } from 'react-icons/fa';
import '../styles/productList.css';

function ProductList() {
    const { farmId } = useFarmContext();
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentProductId, setCurrentProductId] = useState(null);
    const [isCardView, setIsCardView] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [newProduct, setNewProduct] = useState({
        productName: '',
        productDescription: '',
        productUnit: '',
        price: '',
        pricingTier: '',
        unitWeight: '',
    });
    const [productToUpdate, setProductToUpdate] = useState({
        id: '',
        productName: '',
        productDescription: '',
        productUnit: '',
        price: '',
        pricingTier: '',
        unitWeight: '',
    });

    const fetchProducts = useCallback(async () => {
        try {
            const productsQuery = query(
                collection(db, 'products'),
                where('farmId', '==', farmId)
            );
            const productsSnapshot = await getDocs(productsQuery);
            const productsData = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProducts(productsData);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, [farmId]);

    useEffect(() => {
        if (farmId) {
            fetchProducts();
        }
    }, [fetchProducts, farmId]);

    const handleDeleteClick = (id) => {
        setCurrentProductId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentProductId(null);
    };

    const handleConfirmDelete = async () => {
        try {
            const productRef = doc(db, 'products', currentProductId);
            await deleteDoc(productRef);
            setProducts(products.filter(product => product.id !== currentProductId));
            handleCloseModal();
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleUpdateClick = (product) => {
        setProductToUpdate(product);
        setShowUpdateModal(true);
    };

    const handleCreateProduct = async (e) => {
        e.preventDefault();
        try {
            const productRef = await addDoc(collection(db, 'products'), {
                ...newProduct,
                farmId,
            });
            setProducts([...products, { id: productRef.id, ...newProduct }]);
            fetchProducts(); // Refresh product list
            setShowCreateModal(false);
            setNewProduct({
                productName: '',
                productDescription: '',
                productUnit: '',
                price: '',
                pricingTier: '',
                unitWeight: '',
            });
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    const handleUpdateProduct = async (e) => {
        e.preventDefault();
        try {
            const productRef = doc(db, 'products', productToUpdate.id);
            await updateDoc(productRef, {
                ...productToUpdate,
                farmId,
            });
            setProducts(products.map(p => p.id === productToUpdate.id ? { ...productToUpdate } : p));
            fetchProducts(); // Refresh product list
            setShowUpdateModal(false);
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProduct(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUpdateInputChange = (e) => {
        const { name, value } = e.target;
        setProductToUpdate(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="product-list-container">
            <div className="product-list-header">
                <h2>Product List</h2>
                <div className="view-buttons">
                    <button onClick={() => setIsCardView(true)} className={isCardView ? 'active' : ''}>
                        <FaTh />
                    </button>
                    <button onClick={() => setIsCardView(false)} className={!isCardView ? 'active' : ''}>
                        <FaList />
                    </button>
                    <button onClick={() => setShowCreateModal(true)} className="create-button">
                        <FaPlus /> Create Product
                    </button>
                </div>
            </div>
            <ProductFilterBar 
                filters={{ searchId: '', product: 'All' }}
                handleFilterChange={() => {}}
                resetFilters={() => {}}
                locations={[]}
                products={products.map(product => product.productName)}
                showDateFilter={false}
            />
            <div className="product-views">
                {isCardView ? (
                    <div className="product-cards">
                        {products.length > 0 ? (
                            products.map(product => (
                                <ProductCard
                                    key={product.id}
                                    product={product}
                                    handleUpdate={() => handleUpdateClick(product)}
                                    handleDeleteClick={handleDeleteClick}
                                />
                            ))
                        ) : (
                            <p>No products found.</p>
                        )}
                    </div>
                ) : (
                    <ProductSpreadsheetView
                        products={products}
                        handleUpdate={handleUpdateClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                )}
            </div>
            <Modal
                show={showModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this product? This action cannot be undone."
            />
            <ProductModal
                show={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onSubmit={handleCreateProduct}
                product={newProduct}
                handleInputChange={handleInputChange}
                fetchProducts={fetchProducts}
            />
            <UpdateProductModal
                show={showUpdateModal}
                onClose={() => setShowUpdateModal(false)}
                onSubmit={handleUpdateProduct}
                product={productToUpdate}
                handleInputChange={handleUpdateInputChange}
                fetchProducts={fetchProducts}
            />
        </div>
    );
}

export default ProductList;
