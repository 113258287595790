import React, { useState, useEffect, useCallback } from 'react';
import { useFarmContext } from '../contexts/farmContext';
import CustomerFilterBar from './customerFilterBar';
import CustomerCard from './customerCard';
import CustomerSpreadsheetView from './customerSpreadsheetView';
import CustomerModal from './customerModal';
import UpdateCustomerModal from './updateCustomerModal';
import Modal from './modal'; 
import { FaTh, FaList, FaPlus } from 'react-icons/fa';
import '../styles/customerList.css';
import { collection, query, where, getDocs, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

function CustomerList() {
    const { farmId } = useFarmContext();
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentCustomerId, setCurrentCustomerId] = useState(null);
    const [isCardView, setIsCardView] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);  
    const [newCustomer, setNewCustomer] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        type: '',
        businessName: '',
    });
    const [customerToUpdate, setCustomerToUpdate] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        type: '',
        businessName: '',
    });
    const [filters, setFilters] = useState({
        searchName: '',
        name: 'All',
        email: 'All',
        customerType: 'All',
    });

    const fetchCustomers = useCallback(async () => {
        try {
            const customersRef = collection(db, 'customers');
            const q = query(customersRef, where('farmId', '==', farmId));
            const querySnapshot = await getDocs(q);
            const customersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCustomers(customersData);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    }, [farmId]);

    useEffect(() => {
        if (farmId) {
            fetchCustomers();
            const interval = setInterval(fetchCustomers, 60000); // Re-fetch customers every minute
            return () => clearInterval(interval);
        }
    }, [fetchCustomers, farmId]);

    const handleDeleteClick = (id) => {
        setCurrentCustomerId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentCustomerId(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteDoc(doc(db, 'customers', currentCustomerId));
            setCustomers(customers.filter(customer => customer.id !== currentCustomerId));
            handleCloseModal();
        } catch (error) {
            console.error('Error deleting customer:', error);
        }
    };

    const handleUpdateClick = (customer) => {
        setCustomerToUpdate(customer);
        setShowUpdateModal(true);
    };

    const handleCreateCustomer = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, 'customers'), {
                ...newCustomer,
                farmId,
            });
            setCustomers([...customers, { id: docRef.id, ...newCustomer }]);
            setShowCreateModal(false);
            setNewCustomer({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                type: '',
                businessName: '',
            });
        } catch (error) {
            console.error('Error creating customer:', error);
        }
    };

    const handleUpdateCustomer = async (e) => {
        e.preventDefault();
        try {
            const customerRef = doc(db, 'customers', customerToUpdate.id);
            await updateDoc(customerRef, { ...customerToUpdate });
            setCustomers(customers.map(c => c.id === customerToUpdate.id ? customerToUpdate : c));
            setShowUpdateModal(false);
        } catch (error) {
            console.error('Error updating customer:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCustomer(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUpdateInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerToUpdate(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const resetFilters = () => {
        setFilters({
            searchName: '',
            name: 'All',
            email: 'All',
            customerType: 'All',
        });
    };

    const filteredCustomers = customers.filter(customer => {
        const fullName = `${customer.firstName} ${customer.lastName}`.toLowerCase();
        const isSearchNameMatch = filters.searchName === '' || fullName.includes(filters.searchName.toLowerCase());
        const isNameMatch = filters.name === 'All' || fullName.includes(filters.name.toLowerCase());
        const isEmailMatch = filters.email === 'All' || customer.email.includes(filters.email.toLowerCase());
        const isCustomerTypeMatch = filters.customerType === 'All' || customer.type === filters.customerType;
        return isSearchNameMatch && isNameMatch && isEmailMatch && isCustomerTypeMatch;
    });

    return (
        <div className="customer-list-container">
            <div className="header">
                <h2>Customer List</h2>
                <div className="view-buttons">
                    <button onClick={() => setIsCardView(true)} className={isCardView ? 'active' : ''}>
                        <FaTh />
                    </button>
                    <button onClick={() => setIsCardView(false)} className={!isCardView ? 'active' : ''}>
                        <FaList />
                    </button>
                    <button onClick={() => setShowCreateModal(true)} className="create-button">
                        <FaPlus /> Create Customer
                    </button>
                </div>
            </div>
            <CustomerFilterBar
                filters={filters}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
                locations={[]} // No locations needed for customers
                products={[]} // No products needed for customers
                showDateFilter={false}
            />
            <div className="customer-views">
                {isCardView ? (
                    <div className="customer-cards">
                        {filteredCustomers.length > 0 ? (
                            filteredCustomers.map(customer => (
                                <CustomerCard
                                    key={customer.id}
                                    customer={customer}
                                    handleUpdate={() => handleUpdateClick(customer)}
                                    handleDeleteClick={handleDeleteClick}
                                    buttonClass="button"
                                />
                            ))
                        ) : (
                            <p>No customers found.</p>
                        )}
                    </div>
                ) : (
                    <CustomerSpreadsheetView
                        customers={filteredCustomers}
                        handleUpdate={handleUpdateClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                )}
            </div>
            <Modal
                show={showModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this customer? This action cannot be undone."
            />
            <CustomerModal
                show={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onSubmit={handleCreateCustomer}
                customer={newCustomer}
                handleInputChange={handleInputChange}
            />
            <UpdateCustomerModal
                show={showUpdateModal}
                onClose={() => setShowUpdateModal(false)}
                onSubmit={handleUpdateCustomer}
                customer={customerToUpdate}
                handleInputChange={handleUpdateInputChange}
            />
        </div>
    );
}

export default CustomerList;
